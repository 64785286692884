<template>
  <page-layout>
    <v-col cols="12" class="help-page">
      <simple-card>
        <template v-slot:title-left>Помощь</template>
        <template v-slot:content>
          <div class="text-h6 mb-4 text-center">Как привязать лицевой счет</div>
          <div>
            <div class="mb-2">
              После процедуры авторизации ваш ЛС должен отразится в ЛК
              автоматически. Если у вас несколько лицевых счетов, вы можете
              выполнить привязку и управлять ими в одном личном кабинете. Для
              этого необходимо выбрать в верхнем меню кнопку «ПРИВЯЗКА ЛС».
              Заполнить форму «Добавить адрес».
            </div>
            <div>
              <div>1. Указать номер привязываемого ЛС</div>
              <div>
                <div>2. Выбрать способ привязки</div>
                <div class="pl-2">
                  <div>- номер прибора учета</div>
                  <div>- сумма последней оплаты</div>
                  <div>
                    - сумма последнего начисления (сумма ЕПД с/без страховки или
                    сумма начислений за последние 12 месяцев)
                  </div>
                </div>
              </div>
              <div>3. Ввести выбранное значение.</div>
              <p>
                Переходы между ЛС осуществляются в верхнем меню в окошке выбора.
              </p>
              <p class="font-italic">
                К одному личному кабинету можно привязать не более 3-х ЛС.
              </p>
              <p>
                При необходимости Вы можете удалить привязанный ЛС, переместив
                его в «корзину» в разделе «Привязка ЛС» - «Текущие привязки».
              </p>

              <v-img
                class="mx-auto my-2"
                contain
                src="../../assets/images/help/help-binding.png"
              />
            </div>
          </div>
          <div class="text-h6 my-4 text-center mt-4">
            Как посмотреть начисления и оплатить
          </div>
          <p>
            Войдите в пункт меню «Мой аккаунт». На странице доступна информация
            о начислениях, задолженности и оплатах за
            <span class="text-decoration-underline">отчетный период</span>.
          </p>
          <v-img
            class="mx-auto my-2"
            :width="width50percent"
            src="../../assets/images/help/help-balance.png"
          />
          <p>
            Для оплаты Вы можете выбрать вариант «СО СТРАХОВКОЙ» и «БЕЗ».
            Кликните по выбранному варианту и перейдите на страницу «Оплата».
            <span class="text-decoration-underline"
              >При желании Вы можете указать любую произвольную сумму к
              оплате</span
            >. Для получения фискального чека об оплате не забудьте указать
            адрес электронной почты.
          </p>
          <v-img
            class="mx-auto my-2"
            :width="width80percent"
            contain
            src="../../assets/images/help/help-pay.png"
          />
          <p class="text-decoration-underline">
            Порядок и условия оплаты по картам
          </p>
          <p>
            Используя форму оплаты, укажите свой лицевой счет и сумму, которую
            вы хотите внести. Затем нажмите на кнопку «Оплатить». Вы будете
            переведены на защищенную страницу, расположенную на сайте платежной
            системы, где безопасно осуществите свой платеж и будете возвращены
            обратно в личный кабинет.
          </p>
          <p>
            Оплата будет проведена <b>БЕЗ КОМИССИИ</b> любой эмитированной в РФ
            картой, как дебетовой, так и кредитной.<br />
            Также доступна оплата через Google Pay.
          </p>
          <v-img
            class="mx-auto my-2"
            :width="width80percent"
            contain
            src="../../assets/images/banks.jpg"
          />
          <p class="text-decoration-underline">
            На странице Банка необходимо заполнить информацию:
          </p>
          <ul>
            <li>Тип платежной системы (Visa, MasterCard, МИР).</li>
            <li>
              Номер карты и CVV / CVC-код. Большинство кредитных и дебетовых
              карт содержат CVV/ CVC-код (Card Verification Value / Card
              Verification Code) – в виде последних трех цифр на обратной
              стороне кредитной карты в поле подписи. Карты имеют четырехзначный
              код CVV/ CVC-код номер, расположенный на лицевой стороне карты в
              правом углу над номером карты.
            </li>
            <li>
              Имя держателя карты и фамилию (как они написаны на карте) и дату
              истечения срока, который написан на лицевой стороне карты. Если на
              банковской карте вместо полного имени указано сокращение (например
              «А» вместо «ALEXEY») необходимо вносить ПОЛНОЕ имя, поскольку
              система не принимает имя длиной менее трех букв. Неименные
              банковский карты также принимаются к оплате. Указывается на
              платежной странице наименование, эмбоссируемое на карте.
            </li>
            <li>
              Пожалуйста, убедитесь, что на карте достаточно средств для оплаты
              вашего заказа. После проверки лицевого счета и корректности
              введенной суммы, с карты произойдет списание средств. Платеж будет
              зачислен автоматически.
            </li>
          </ul>

          <v-img
            class="mx-auto my-2"
            :width="width80percent"
            contain
            src="../../assets/images/help/help-payment-page.png"
          />

          <p class="text-decoration-underline">Политика конфиденциальности</p>
          <p>
            Оплата в Интернете с помощью банковской карты производится через
            авторизационный сервер.
          </p>
          <p>
            Для осуществления платежа вам необходимо сообщить данные вашей
            пластиковой карты. Сервис Недолжник.рф не получает и не хранит ваши
            данные. Передача этих сведений на авторизационный сервер
            осуществляется только по каналам связи, защищенным с помощью
            современных протоколов обеспечения безопасности в интернет.
          </p>

          <p class="text-decoration-underline">
            Положение о защищенности передаваемых данных
          </p>
          <p>
            Безопасность платежей обеспечивается с помощью Банка-эквайера,
            функционирующего на основе современных протоколов и технологий,
            разработанных международными платежными системами Visa
            International, MasterCard Worldwide и MirAccept (3D-Secure: Verified
            by VISA, MasterCard SecureCode).
          </p>
          <p>
            Обработка полученных конфиденциальных данных Держателя карты
            производится в процессинговом центре Банка-эквайера по закрытым
            сетям передачи данных, сертифицированным международными платежными
            системами для доставки конфиденциальной финансовой информации.
          </p>

          <div class="text-h6 my-4 text-center mt-4">Как отменить платеж</div>

          <p class="text-decoration-underline">
            Возврат денежных средств «день в день»
          </p>
          <p>
            В день, когда была произведена оплата необходимо обратиться с
            просьбой о возврате денежных средств в чат портала Недолжник.рф, по
            телефонам или адресам электронной почты info@nedolzhnik.ru (для
            жителей г.Королев) или reutov@nedolzhnik.ru (для г.Реутов и
            г.Балашиха) с подтверждением, что ваше обращение принято к
            рассмотрению.
          </p>
          <p>
            В сообщении необходимо указать номер ЛС, точную сумму оплаты, а
            также причину для отмены платежа. Возврат может быть осуществлен
            ТОЛЬКО В РАБОЧИЕ ДНИ с 9.00 до 18.00.
          </p>

          <p class="text-decoration-underline">
            Возврат денежных средств не в день проведения оплаты
          </p>
          <p>
            Возврат денежных средств осуществляется с письменного заявления
            плательщика при личном обращении в территориальное управление ООО
            «МосОблЕИРЦ» по месту территориальной принадлежности. Кроме того,
            нужно подготовить копии документов, подтверждающих оплату.
          </p>

          <div class="text-h6 my-4 text-center mt-4">История платежей</div>

          <p>Выберите пункт меню «История платежей».</p>
          <p>
            На странице будет отражена полная история ваших оплат: дата,
            источник платежа, сумма, статус.
          </p>

          <p class="text-decoration-underline">
            В ЛК отражаются три варианта статуса оплаты:
          </p>
          <p>
            - «Идет оплата» - запущен процесс оплаты, статус платежа уточняется
            в Банке
          </p>
          <p>
            - «Отправлен» - платеж принят Банком и отправлен в Расчетный центр
          </p>
          <p>
            - «Принят» - оплата проведена через Расчетный центр и зачислена на
            лицевой счет
          </p>

          <v-img
            class="mx-auto my-2"
            contain
            src="../../assets/images/help/help-payment-history.png"
          />

          <p>Срок проведения платежа – до 7-х рабочих дней.</p>

          <p>При необходимости, со страницы доступна ссылка на Оплату.</p>

          <div class="text-h6 my-4 text-center mt-4">
            Начисления, платежи, ЕПД
          </div>

          <p>
            На странице доступна полная информация по начислениям с расшифровкой
            по услугам за отчетный месяц, а также архив начислений.
          </p>

          <p class="text-decoration-underline">
            На странице есть возможность загрузить квитанцию к оплате
          </p>
          <p>
            Квитанция будет загружена в формате .pdf. Для ее просмотра можно
            воспользоваться любым программным обеспечением, которое откроет
            данный формат файла.
          </p>

          <v-img
            class="mx-auto my-2"
            src="../../assets/images/help/help-receipt.png"
          />

          <div class="text-h6 my-4 text-center mt-4">
            Как внести показания счетчиков
          </div>

          <p>
            Для ввода показаний перейдите в пункт меню «Показания счетчиков».
            Перечень ваших счетчиков будет отражен на странице передачи
            показаний. Для передачи новых значений используйте соответствующие
            формы ввода показаний.
          </p>
          <p>
            После того, как Вы убедитесь в правильности введенных данных,
            нажмите кнопку «Внести показания» в синем кружочке со стрелкой.
          </p>
          <p>После обработки показания отразятся в «Истории показаний».</p>

          <v-img
            class="mx-auto my-2"
            contain
            src="../../assets/images/help/help-ipu.png"
          />

          <p>
            Будьте внимательны! Если ошибочно будут переданы показания больше
            фактического значения, то внести показания с меньшем значением Вы не
            сможете. Большее значение для ввода доступно.
          </p>

          <p class="font-italic error--text">
            Важно! Формы ввода показаний активны в период, установленный
            расчетным Центром. Период передачи на портале Недолжник.рф
            открывается первого числа месяца, дата окончания может меняться.
            Следите за анонсами.
          </p>

          <div class="text-h6 my-4 text-center">
            Почему в личном кабинете нет счетчиков
          </div>

          <p>Причин может быть несколько:</p>
          <ul>
            <li>
              Закончился срок действия прибора учёта и нужна поверка. Сроки
              можно посмотреть в квитанции, которая приходит на почту. Если вы
              обнаружили, что срок действия вашего ИПУ закончился, сделайте
              поверку и предоставьте документы в ЕИРЦ.
            </li>
            <li>
              Информация о приборе учета не была передана и счётчик не
              зарегистрирован в системе. В таком случае вам нужно предоставить
              акт приемки-передачи ИПУ в УК или МосОблЕИРЦ.
            </li>
            <li>
              Вашей управляющей компанией осуществлялся поквартирный обход с
              целью проверки приборов учета и представителю не был предоставлен
              доступ. Если вы столкнулись с такой проблемой, вам следует
              обратиться в УК и договориться о новой встрече.
            </li>
          </ul>

          <div class="text-h6 my-4 text-center">Выход из ЛК</div>
          <p>
            Иногда может потребоваться выход из личного кабинета. Например,
            чтобы войти под другим профилем. Для этого в верхней правой части
            экрана кликните по иконке со стрелочкой.
          </p>

          <v-img
            class="mx-auto my-2"
            contain
            src="../../assets/images/help/help-logout.png"
          />

          <div class="text-h6 my-4 text-center">
            Как посмотреть задолженность/оплатить, передать показания ИПУ без
            авторизации
          </div>
          <p>
            Откройте страницу лк.недолжник.рф, выберете «Оплатить» или «Передать
            показания».
          </p>

          <v-img
            class="mx-auto my-2"
            contain
            src="../../assets/images/help/help-no-auth.png"
          />
        </template>
      </simple-card>
    </v-col>
  </page-layout>
</template>

<script>
import PageLayout from "../../components/Layouts/PageLayout";
import SimpleCard from "../../components/cards/SimpleCard";

export default {
  name: "FAQ",
  components: { PageLayout, SimpleCard },
  computed: {
    width50percent() {
      return this.$vuetify.breakpoint.lgAndUp ? "50%" : null;
    },
    width80percent() {
      return this.$vuetify.breakpoint.lgAndUp ? "80%" : null;
    },
  },
};
</script>

<style lang="scss">
.help-page {
  text-align: left;
}
</style>
